import React from 'react'
import Layout from "../components/layout"
import donJohn from '../images/don_jon.png'
import dogberry from '../images/dogberry-day-14.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Don John apprehended, Dogberry praised" image={donJohn} />
            <h1>
                Don John apprehended, Dogberry praised 
            </h1>
            <img src={donJohn} />
            <p>Don John was apprehended yesterday after a high-speed chase in the late afternoon. He had been hiding in plain sight for days, explained Dogberry, who is credited with bringing the real villain behind the scandal to justice.</p> 

            <p>When he spotted Don John attempting to flee town on a moped, Dogberry jumped on the back and subdued the suspect. Don John is being held in the Messina jail while he awaits his court date. Margaret asserts that she will file additional civil charges against him.</p>

            <p>Dogberry has been hailed as a hero and will receive a key to the city next week. When asked about the upcoming ceremony, he responded, “Great, now I can add locksmith to my services.”</p>

            <img className="dogberry-ad" src={dogberry} />
        </Layout>
    )
}
